<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>CCTV관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="padding: 40px; margin: 0">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="display: flex; align-items: center; justify-content: center; width: 300px; margin-left: 10px">
							<div class="form-group">
								<label>{{ detailsFieldMap.siteNm }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.siteNm" :disabled="isSiteDisabled">
										<option value="">전체</option>
										<option v-for="(row, index) in options.siteOptions" :key="index" :value="row.siteNm">{{ row.siteNm }}</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- CCTV 설치 장소 -->
						<div class="col-md-3" style="width: 300px; margin-right: 10px">
							<div class="form-group">
								<label>{{ detailsFieldMap.cctvInstallPlaceCont }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									:placeholder="detailsFieldMap.cctvInstallPlaceCont"
									v-model="searchVM.cctvInstallPlaceCont"
									:maxlength="maxLength.cctvInstallPlaceCont"
								/>
							</div>
						</div>
						<!-- CCTV 설치 장소 -->
						<div class="col-md-3" style="width: 330px; margin-left: 10px">
							<div class="form-group">
								<label>{{ detailsFieldMap.cctvTypeDvsnCd }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.cctvTypeDvsnCd">
										<option value="">전체</option>
										<option v-for="(item, index) in options.cctvTypeDvsnCdOptions" :key="index" :value="item.cd">{{ item.cdNm }}</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- 사용여부 -->
						<div class="col-md-3" style="width: 200px">
							<div class="form-group">
								<label>{{ detailsFieldMap.useYn }}</label>
								<select2 v-model="searchVM.useYn" :options="options.ynOptions">
									<option value="">전체</option>
								</select2>
							</div>
						</div>
						<!-- 검색 버튼 -->
						<div class="text-right" style="display: flex; align-items: center; justify-content: center; margin: 0">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="row in-panel-body">
						<fieldset>
							<legend class="text-semibold">
								<i class="icon-cog3 position-left"></i>
								CCTV 정보
							</legend>
							<div class="col-lg-6 inputDiv1">
								<!-- CCTV ID, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.cctvId"
									v-model="detailsItem.cctvId"
									required="true"
									:disabled="!isCreateMode"
									v-if="isEditMode"
									field="detailsItem.cctvId"
									data-vv-name="detailsItem.cctvId"
								></jarvis-field>
								<!-- CCTV 설치 장소 -->
								<jarvis-field
									:label="detailsFieldMap.cctvInstallPlaceCont"
									id="firstInputCreate"
									field="detailsItem.cctvInstallPlaceCont"
									required="true"
									v-model="detailsItem.cctvInstallPlaceCont"
									v-validate="validationRule.detailsItem.cctvInstallPlaceCont"
									data-vv-name="detailsItem.cctvInstallPlaceCont"
									:maxLength="maxLength.cctvInstallPlaceCont"
								></jarvis-field>
								<!-- 현장명 - 등록시만 입력 가능하게 -->
								<jarvis-field :label="detailsFieldMap.siteNm" field="detailsItem.siteNm" required="true" v-if="isCreateMode">
									<select2
										v-model="detailsItem.siteId"
										data-vv-name="detailsItem.siteNm"
										v-validate="validationRule.detailsItem.siteId"
										:options="options.siteOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 현장명 - 수정시에는 보여주기만 -->
								<jarvis-field
									:label="detailsFieldMap.siteNm"
									v-model="detailsItem.siteNm"
									:disabled="true"
									v-if="!isCreateMode"
									data-vv-name="detailsItem.siteNm"
									v-validate="validationRule.detailsItem.cctvId"
								></jarvis-field>
								<!-- CCTV 설치 구분 코드 -->
								<jarvis-field :label="detailsFieldMap.cctvInstallDvsnCd" field="detailsItem.cctvInstallDvsnCd" required="true">
									<select2
										v-model="detailsItem.cctvInstallDvsnCd"
										data-vv-name="detailsItem.cctvInstallDvsnCd"
										v-validate="validationRule.detailsItem.cctvInstallDvsnCd"
										:options="options.cctvInstallDvsnCdOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 사용여부 -->
								<jarvis-field :label="detailsFieldMap.useYn" field="detailsItem.useYn" required="true">
									<select2
										v-model="detailsItem.useYn"
										data-vv-name="detailsItem.useYn"
										v-validate="validationRule.detailsItem.useYn"
										:options="options.ynOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- CCTV 종류 구분 코드 -->
								<jarvis-field :label="detailsFieldMap.cctvKindDvsnCd">
									<select2 v-model="detailsItem.cctvKindDvsnCd" :options="options.cctvKindDvsnCdOptions">
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- CCTV 해상도 -->
								<jarvis-field
									:label="detailsFieldMap.cctvResolution"
									v-model="detailsItem.cctvResolution"
									field="detailsItem.cctvResolution"
									data-vv-name="detailsItem.cctvResolution"
									v-validate="validationRule.detailsItem.cctvResolution"
									:maxLength="maxLength.cctvResolution"
								></jarvis-field>
								<!-- CCTV 접속 URL -->
								<jarvis-field
									:label="detailsFieldMap.cctvConnUrl"
									v-model="detailsItem.cctvConnUrl"
									field="detailsItem.cctvConnUrl"
									data-vv-name="detailsItem.cctvConnUrl"
									v-validate="validationRule.detailsItem.cctvConnUrl"
									:maxLength="maxLength.cctvConnUrl"
								></jarvis-field>
								<!-- CCTV 접속 포트 -->
								<jarvis-field
									:label="detailsFieldMap.cctvConnPort"
									v-model="detailsItem.cctvConnPort"
									field="detailsItem.cctvConnPort"
									data-vv-name="detailsItem.cctvConnPort"
									v-validate="validationRule.detailsItem.cctvConnPort"
									:maxLength="maxLength.cctvConnPort"
								></jarvis-field>
								<!-- CCTV PLAY URL -->
								<jarvis-field
									:label="detailsFieldMap.cctvPlayUrl"
									v-model="detailsItem.cctvPlayUrl"
									field="detailsItem.cctvPlayUrl"
									data-vv-name="detailsItem.cctvPlayUrl"
									v-validate="validationRule.detailsItem.cctvPlayUrl"
									:maxLength="maxLength.cctvPlayUrl"
								></jarvis-field>
								<div v-show="isEditMode">
									<!-- 등록일, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.regDtm"
										disabled="true"
										v-show="isEditMode"
										field="detailsItem.regDtm"
										:value="detailsItem.regDtm | toDisplayDateTime"
									></jarvis-field>
									<!-- 등록자, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.regUser"
										disabled="true"
										v-show="isEditMode"
										field="detailsItem.regUser"
										:value="detailsItem.regUser"
									></jarvis-field>
									<!-- 수정일, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.updDtm"
										disabled="true"
										v-show="isEditMode"
										field="detailsItem.updDtm"
										:value="detailsItem.updDtm | toDisplayDateTime"
									></jarvis-field>
									<!-- 수정자, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.updUser"
										disabled="true"
										v-show="isEditMode"
										field="detailsItem.updUser"
										:value="detailsItem.updUser"
									></jarvis-field>
								</div>
							</div>
							<!-- 안전대상물 표시(안전대상물판별여부) -->
							<div class="col-lg-6" style="display: flex; flex-direction: column">
								<div class="col-lg-offset-1" style="border: 1px solid #ddd; padding-top: 40px; margin-bottom: 35px; padding-right: 10px">
									<div>
										<i
											style="color: gray; position: absolute; top: 20px"
											class="icon-question4"
											@mouseover="info($event)"
											@mouseleave="closeInfo($event)"
										></i>
										<span class="tooltipText">
											안전대상물 표시
											<br />
											*사용: 대상물이 안전한 상태인 경우에도 표시합니다.
											<br />
											*미사용: 대상물이 안전한 상태인 경우에는 표시하지 않습니다.
											<br />
											<br />
											대상물명표시
											<br />
											*사용: 판별된 대상의 대상물명과 조치대상명을 표시합니다.
											<br />
											*미사용: 판별된 대상의 조치대상명만 표시합니다.
										</span>
										<div>
											<jarvis-field :label="detailsFieldMap.safeObjectDistigshYn" field="detailsItem.safeObjectDistigshYn" required="true">
												<select2
													v-model="detailsItem.safeObjectDistigshYn"
													:required="true"
													data-vv-name="detailsItem.safeObjectDistigshYn"
													v-validate="validationRule.detailsItem.safeObjectDistigshYn"
													:options="options.ynOptions"
												>
													<option value="">선택</option>
												</select2>
											</jarvis-field>
										</div>
									</div>
									<!-- 대상물명표시(대상물명표시여부) -->
									<div>
										<div>
											<jarvis-field :label="detailsFieldMap.objectNmDisplayYn" field="detailsItem.objectNmDisplayYn" required="true">
												<select2
													v-model="detailsItem.objectNmDisplayYn"
													:required="true"
													data-vv-name="detailsItem.objectNmDisplayYn"
													v-validate="validationRule.detailsItem.objectNmDisplayYn"
													:options="options.ynOptions"
												>
													<option value="">선택</option>
												</select2>
											</jarvis-field>
										</div>
									</div>
									<!-- 세그먼테이션표시여부 -->
									<div>
										<div>
											<jarvis-field :label="detailsFieldMap.sgmtDisplayYn" field="detailsItem.sgmtDisplayYn" required="true">
												<select2
													v-model="detailsItem.sgmtDisplayYn"
													:required="true"
													data-vv-name="detailsItem.sgmtDisplayYn"
													v-validate="validationRule.detailsItem.sgmtDisplayYn"
													:options="options.ynOptions"
												>
													<option value="">선택</option>
												</select2>
											</jarvis-field>
										</div>
									</div>
								</div>
								<div class="col-lg-offset-1" style="border: 1px solid #ddd; margin-bottom: 35px; padding-right: 10px">
									<div style="padding: 20px 0px 20px 0px"><b>ML 엔진 설정</b></div>
									<!-- gpu번호 -->
									<!-- <jarvis-field
										:label="detailsFieldMap.gpuNo"
										v-model="detailsItem.gpuNo"
										field="detailsItem.gpuNo"
										required="true"
										data-vv-name="detailsItem.gpuNo"
										v-validate="validationRule.detailsItem.gpuNo"
									></jarvis-field>
									<div class="row">
										<span style="margin-bottom: 15px; color: red" class="col-md-9 col-md-offset-3">
											※gpu 번호가 여러 개일 경우 쉼표(,)로 구분해서 입력해 주세요.
										</span>
									</div> -->
									<!-- 미디어서버 id -->
									<jarvis-field
										:label="detailsFieldMap.mdserverId"
										required="true"
										v-model="detailsItem.mdserverId"
										field="detailsItem.mdserverId"
										data-vv-name="detailsItem.mdserverId"
										v-validate="validationRule.detailsItem.mdserverId"
										:maxLength="maxLength.mdserverId"
									></jarvis-field>
									<!-- ML 엔진 ID -->
									<jarvis-field
										:label="detailsFieldMap.mlEngineId"
										v-model="detailsItem.mlEngineId"
										field="detailsItem.mlEngineId"
										data-vv-name="detailsItem.mlEngineId"
										v-validate="validationRule.detailsItem.mlEngineId"
										class="ml-engine-field"
									>
										<div v-if="detailsItem.useYn == '1'" class="form-group">
											<div class="form-control">{{ detailsItem.mlEngineId }}</div>
											<div class="btn btn-primary" @click="openSelectMlEngine"><i class="fa fa-search"></i></div>
										</div>
										<div v-if="detailsItem.useYn == '0' || !detailsItem.useYn" class="form-group">
											<div class="form-control" :disabled="true" style="cursor: not-allowed"></div>
											<div class="btn btn-primary" :disabled="true"><i class="fa fa-search"></i></div>
										</div>
									</jarvis-field>
									<!-- CCTV유형구분 -->
									<jarvis-field :label="detailsFieldMap.cctvTypeDvsnCd" field="detailsItem.cctvTypeDvsnCd" required="true">
										<select2
											v-model="detailsItem.cctvTypeDvsnCd"
											data-vv-name="detailsItem.cctvTypeDvsnCd"
											v-validate="validationRule.detailsItem.cctvTypeDvsnCd"
											:options="options.cctvTypeDvsnCdOptions"
										>
											<option value="">선택</option>
										</select2>
									</jarvis-field>
								</div>
								<div class="col-lg-offset-1" style="border: 1px solid #ddd; margin-bottom: 35px; padding-right: 10px">
									<div style="padding: 20px 0px 20px 0px"><b>모니터링 설정</b></div>
									<!-- 모니터링 해상도 -->
									<jarvis-field
										:label="detailsFieldMap.monResolution"
										v-model="detailsItem.monResolution"
										field="detailsItem.monResolution"
										data-vv-name="detailsItem.monResolution"
										v-validate="validationRule.detailsItem.monResolution"
									></jarvis-field>

									<!-- 모니터링 품질 -->
									<jarvis-field
										:label="detailsFieldMap.monQlty"
										v-model="detailsItem.monQlty"
										field="detailsItem.monQlty"
										data-vv-name="detailsItem.monQlty"
										v-validate="validationRule.detailsItem.monQlty"
									></jarvis-field>
								</div>
							</div>
						</fieldset>
					</div>
					<div>
						<div style="font-size: 12px; color: red; margin-bottom: 10px">
							CCTV 접속 URL, CCTV 접속 포트, CCTV PLAY URL, ML 엔진 설정, 모니터링 설정 등의 경우 ML서버 재시작 후 추가 및 수정 내역이 반영됩니다.
							<br />
							원활한 사용을 위해 CCTV 정보 추가 및 수정 후엔 ML서버를 재시작해주세요.
						</div>
						<div class="text-right" v-if="!isEditMode">
							<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
								<b><i class="icon-cross"></i></b>
								닫기
							</button>
							<button type="button" class="btn btn-labeled bg-primary" @click="createData">
								<b><i class="icon-checkmark3"></i></b>
								저장
							</button>
						</div>
						<div class="text-right" v-if="isEditMode">
							<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
								<b><i class="icon-cross"></i></b>
								닫기
							</button>
							<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
								<b><i class="icon-minus3"></i></b>
								삭제
							</button>
							<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
								<b><i class="icon-checkmark3"></i></b>
								저장
							</button>
						</div>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
		<div class="popup ml-engine-select-popup" ref="mlEngineSelectPopup">
			<div class="background" @click="closeMlEngineSelectPopup" />
			<div class="wrapper">
				<div class="top">
					ML 엔진 선택
					<i class="fa fa-times" @click="closeMlEngineSelectPopup"></i>
				</div>
				<div class="middle">
					<div class="ml-engine-list">
						<label>ML 엔진(최대 연결 수: {{ maxConnectionCount }})</label>
						<div class="table" ref="mlEngineListTable">
							<div class="thead">
								<div class="tr">
									<span>ID</span>
									<span>PORT</span>
									<span>감지 모델</span>
									<span>분류 모델</span>
									<span>연결</span>
									<span></span>
								</div>
							</div>
							<div class="tbody">
								<div
									:class="`tr ${i == selectedMlEngineIdx ? 'selected' : ''}`"
									v-for="(mlEngine, i) in mlEngineList"
									:key="mlEngine.mlEngineId"
									@click="clickMlEngine(i)"
								>
									<span>{{ mlEngine.mlEngineId }}</span>
									<span>{{ mlEngine.mlEngineConnPort }}</span>
									<span>{{ options.detectModelDvsnCdOptions.find(o => o.cd === mlEngine.detectModelDvsnCd).cdNm }}</span>
									<span>{{ options.classifyModelDvsnCdOptions.find(o => o.cd === mlEngine.classifyModelDvsnCd).cdNm }}</span>
									<span>{{ mlEngine.connectionCount }}</span>
									<span>
										<button class="btn btn-default" @click="clickEditMlEngineButton($event, mlEngine)"><i class="fa fa-pencil" /></button>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="cctv-list">
						<label>연결된 CCTV</label>
						<div class="table">
							<div class="thead">
								<div class="tr">
									<span>현장</span>
									<span>CCTV ID</span>
									<span>CCTV 이름</span>
								</div>
							</div>
							<div class="tbody">
								<div class="tr" v-for="cctv in mlEngineCctvList" :key="cctv.cctvId">
									<span>{{ cctv.siteNm }}</span>
									<span>{{ cctv.cctvId }}</span>
									<span>{{ cctv.cctvNm }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bottom">
					<button class="btn btn-default" @click="clickAddMlEngineButton">
						<i class="fa fa-plus"></i>
						ML 엔진 추가
					</button>
					<button v-if="!mlEngineCctvList.length && selectedMlEngineIdx >= 0" class="btn btn-danger" @click="clickDelMlEngineButton">
						<i class="fa fa-trash"></i>
						ML 엔진 삭제
					</button>
					<button
						class="btn btn-primary"
						@click="clickSelectMlEngineButton"
						:disabled="selectedMlEngineIdx < 0 || maxConnectionCount == mlEngineCctvList.length"
					>
						선택
					</button>
				</div>
			</div>
		</div>
		<div class="popup ml-engine-edit-popup" ref="mlEngineEditPopup">
			<div class="background" @click="closeMlEngineEditPopup" />
			<div class="wrapper">
				<div class="top">
					ML 엔진 {{ isEditMlEngine ? '수정' : '추가' }}
					<i class="fa fa-times" @click="closeMlEngineEditPopup"></i>
				</div>
				<div class="middle">
					<label>감지 모델</label>
					<select2
						v-model="mlDetailsItem.detectModelDvsnCd"
						:value="mlDetailsItem.detectModelDvsnCd"
						data-vv-name="mlDetailsItem.detectModelDvsnCd"
						:options="options.detectModelDvsnCdOptions"
					>
						<option value="">선택</option>
					</select2>
					<label>분류 모델</label>
					<select2
						v-model="mlDetailsItem.classifyModelDvsnCd"
						:value="mlDetailsItem.classifyModelDvsnCd"
						data-vv-name="mlDetailsItem.classifyModelDvsnCd"
						:options="options.classifyModelDvsnCdOptions"
					>
						<option value="">선택</option>
					</select2>
				</div>
				<div class="bottom">
					<button v-if="!isEditMlEngine" class="btn btn-primary" @click="clickSaveMlEngineButton">추가</button>
					<button v-if="isEditMlEngine" class="btn btn-primary" @click="clickUpdateMlEngineButton">수정</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention;
const cctvApi = apiIndex.cctv;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		cctvId: 'max:10|required',
		siteId: 'max:10|required',
		cctvInstallDvsnCd: 'required',
		cctvResolution: 'numOnly',
		cctvConnUrl: 'max:200',
		cctvConnPort: 'numOnly|max:6',
		cctvPlayUrl: 'max:200',
		useYn: 'required',
		installDtm: 'required',
		cctvInstallPlaceCont: 'max:500|required',
		safeObjectDistigshYn: 'required',
		objectNmDisplayYn: 'required',
		sgmtDisplayYn: 'required',
		mdserverId: 'max:30|required',
		// mlEngineId: 'required',
		cctvTypeDvsnCd: 'required',
		monResolution: 'numOnly',
		monQlty: 'numOnly',
		// gpuNo: 'numCommaOnly|required',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

const maxLength = {
	cctvId: '10',
	siteId: '10',
	cctvInstallPlaceCont: '500',
	cctvResolution: '10',
	cctvConnUrl: '200',
	cctvConnPort: '6',
	cctvPlayUrl: '200',
	mdserverId: '30',
};
_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('cctvPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}

		// 기존 라이브러리를 사용하기 위해 변환
		this.pageParam.ynOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.cctvInstallDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.cctvKindDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.cctvTypeDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.detectModelDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.classifyModelDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		//기본 현장 세팅
		this.searchVM.siteNm = this.pageParam.siteOptions.filter(e => e.siteId == this.pageParam.loginUserSiteId)[0].siteNm;
		this.pageParam.siteOptions.forEach(e => {
			e.text = e.siteNm;
			e.value = e.siteId;
		});
		this.options = {
			ynOptions: this.pageParam.ynOptions,
			cctvInstallDvsnCdOptions: this.pageParam.cctvInstallDvsnCdOptions,
			cctvKindDvsnCdOptions: this.pageParam.cctvKindDvsnCdOptions,
			cctvTypeDvsnCdOptions: this.pageParam.cctvTypeDvsnCdOptions,
			siteOptions: this.pageParam.siteOptions,
			detectModelDvsnCdOptions: this.pageParam.detectModelDvsnCdOptions,
			classifyModelDvsnCdOptions: this.pageParam.classifyModelDvsnCdOptions,
		};

		this.gridColumns.filter(item => item.field == 'cctvInstallDvsnCd')[0].mapData = this.options.cctvInstallDvsnCdOptions;
		this.gridColumns.filter(item => item.field == 'cctvKindDvsnCd')[0].mapData = this.options.cctvKindDvsnCdOptions;
		this.gridColumns.filter(item => item.field == 'cctvTypeDvsnCd')[0].mapData = this.options.cctvTypeDvsnCdOptions;
		this.gridColumns.filter(item => item.field == 'useYn')[0].mapData = this.options.ynOptions;
		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });

		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.search();
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			pageListApi: cctvApi.inqCctv,
			detailApi: cctvApi.inqOneCctv,
			delApi: cctvApi.delCctv,
			excelApi: cctvApi.exlCctv,
			insApi: cctvApi.insCctv,
			updApi: cctvApi.updCctv,
		},
		//검색VM
		searchVM: {
			siteNm: null,
			cctvInstallPlaceCont: null,
			useYn: '1', // 사용
		},
		options: {},
		searchState: {}, //적용된 검색조건
		//그리드설정
		gridColumns: [
			{ field: 'siteNm', title: '현장명', width: '12%' },
			{ field: 'cctvInstallPlaceContOne', title: '설치장소', width: '12%' },
			{
				field: 'cctvInstallDvsnCd',
				title: '설치구분',
				width: '6%',
				dataFormat: 'optionMap',
				align: 'center',
			},
			{
				field: 'cctvKindDvsnCd',
				title: '종류구분',
				width: '6%',
				dataFormat: 'optionMap',
				align: 'center',
			},
			{
				field: 'cctvTypeDvsnCd',
				title: '유형구분',
				width: '6%',
				dataFormat: 'optionMap',
				align: 'center',
			},
			{
				field: 'cctvResolution',
				title: '해상도',
				width: '4%',
				align: 'right',
				dataFormat: 'numberFormat',
			},
			{
				field: 'cctvConnUrl',
				title: 'CCTV 접속 URL',
				align: 'left',
			},
			{
				field: 'cctvConnPort',
				title: '접속 포트',
				width: '6%',
				align: 'right',
			},
			// {
			// 	field: 'gpuNo',
			// 	title: 'gpu 번호',
			// 	width: '5%',
			// },
			{
				field: 'monResolution',
				title: '모니터링 해상도',
				width: '8%',
				align: 'right',
				dataFormat: 'numberFormat',
			},
			{
				field: 'monQlty',
				title: '모니터링 품질',
				width: '7%',
				align: 'right',
				dataFormat: 'numberFormat',
			},
			{
				field: 'useYn',
				title: '사용여부',
				width: '5%',
				align: 'center',
				dataFormat: 'optionMap',
			},
			{
				field: 'regDtm',
				title: '등록일',
				width: '6%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{
				field: 'updDtm',
				title: '수정일',
				width: '6%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
		],
		//상세필드명맵
		detailsFieldMap: {
			cctvId: 'CCTV ID',
			siteNm: '현장명',
			cctvInstallDvsnCd: 'CCTV 설치 구분',
			cctvKindDvsnCd: 'CCTV 종류 구분',
			cctvTypeDvsnCd: 'CCTV 유형 구분',
			cctvResolution: 'CCTV 해상도',
			cctvConnUrl: 'CCTV 접속 URL',
			cctvConnPort: 'CCTV 접속 포트',
			cctvPlayUrl: 'CCTV PLAY URL',
			useYn: '사용 여부',
			installDtm: '설치 일시',
			cctvInstallPlaceCont: 'CCTV 설치 장소',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '수정일',
			updUser: '수정자',
			safeObjectDistigshYn: '안전대상물표시',
			objectNmDisplayYn: '대상물명표시',
			sgmtDisplayYn: '세그먼테이션표시',
			// gpuNo: 'gpu번호',
			mdserverId: 'ML_SERVER_ID',
			mlEngineId: 'ML_ENGINE_ID',
			detectModelDvsnCd: '탐지 엔진 구분',
			monResolution: '모니터링 해상도',
			monQlty: '모니터링 품질',
		},
		//인풋 최댓값 설정
		maxLength,
		detailsItemOriginal: {}, //상세편집 원본
		detailsItem: {}, //상세편집 수정본
		mlDetailsItem: {}, // ml 엔진 추가/수정용
		errorInstallDtm: '',
		debug: false,
		isSiteDisabled: false,
		//ML 엔진 팝업 관련
		mlEngineList: [],
		maxConnectionCount: 0,
		mlEngineCctvList: [],
		selectedMlEngineIdx: -1,
		isEditMlEngine: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				rule = detailsValidationRule.edit;
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		// isCreateMode() {
		// 	let element = document.getElementsByClassName('inputDiv1')[0];
		// 	if (this.isCreateMode) {
		// 		element.classList.add('col-lg-offset-3');
		// 	} else {
		// 		element.classList.remove('col-lg-offset-3');
		// 	}
		// },
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		// info tooltip 제거
		closeInfo(event) {
			let element = event.srcElement.nextElementSibling;
			element.style.visibility = 'hidden';
		},
		// info tooltip 생성
		info(event) {
			let element = event.srcElement.nextElementSibling;
			element.style.visibility = 'visible';
		},
		// 첫번째 인풋창에 포커스 두도록
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		checkInstallDtmValue() {
			if (this.detailsItem.installDtm == null || this.detailsItem.installDtm == '') {
				this.errorInstallDtm = 'reqInstallDtd';
			} else {
				this.errorInstallDtm = '';
			}
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.$nextTick(function () {
				this.loadGrid();
			});
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = 'CCTV관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			this.errorInstallDtm = '';
			if (!_.isEmpty(selectedRowItem)) {
				this.$axios
					.post(cctvApi.inqOneCctv, { cctvId: selectedRowItem.cctvId })
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true, {}, response.data);
							this.detailsItem = $.extend(true, {}, response.data);
							this.detailsItem.installDtm = this.detailsItem.cctvInstallVO.installDtm;

							//data에서 미리 선언되어 있지 않은 변수에 값을 할당해 바인딩하기 위해 set해줌.
							// this.$set(this.detailsItem, 'oriSiteId', this.detailsItem.siteId);
							this.$set(this.detailsItem, 'cctvInstallPlaceCont', this.detailsItem.cctvInstallVO.cctvInstallPlaceCont);
							setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.errorInstallDtm = '';
			this.detailsItemOriginal = {};
			this.detailsItem = {
				siteId: null,
				cctvInstallDvsnCd: null,
				cctvKindDvsnCd: null,
				cctvTypeDvsnCd: null,
				cctvResolution: null,
				cctvConnUrl: null,
				cctvConnPort: null,
				cctvPlayUrl: null,
				useYn: 1,
				installDtm: null,
				cctvInstallPlaceCont: null,
				cctvInstallVO: {
					installDtm: null,
					cctvInstallPlaceCont: null,
				},
				safeObjectDistigshYn: 1,
				objectNmDisplayYn: 0,
				// gpuNo: null,
				mdserverId: null,
				monResolution: null,
				monQlty: null,
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//신규저장
		createData() {
			this.$validator.validateAll().then(success => {
				this.checkInstallDtmValue();

				if (success === false) {
					return;
				}

				// 미사용 CCTV는 ML 엔진 ID를 강제로 비움
				if (this.detailsItem.useYn === '0') {
					this.detailsItem.mlEngineId = null;
				}

				this.detailsItem.cctvInstallVO.installDtm = this.detailsItem.installDtm;
				this.detailsItem.cctvInstallVO.cctvInstallPlaceCont = this.detailsItem.cctvInstallPlaceCont;

				this.$axios
					.post(this.apiUrl.insApi, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			this.$validator.validateAll().then(success => {
				if (success === false) {
					return;
				}

				if (this.detailsItem.installDtm == null || this.detailsItem.installDtm == '') {
					return;
				}

				// 미사용 CCTV는 ML 엔진 ID를 강제로 비움
				if (this.detailsItem.useYn === '0') {
					this.detailsItem.mlEngineId = null;
				}

				this.detailsItem.cctvInstallVO.installDtm = this.detailsItem.installDtm;
				this.detailsItem.cctvInstallVO.cctvInstallPlaceCont = this.detailsItem.cctvInstallPlaceCont;

				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					this.$axios
						.post(this.apiUrl.updApi, this.detailsItem)
						.then(
							function (r) {
								r;
								this.loadGrid();
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(this.apiUrl.delApi, this.detailsItem)
				.then(
					function (r) {
						r;
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		// ML 엔진 선택 팝업 열기
		openSelectMlEngine(isAutoScroll = true) {
			this.$refs.mlEngineSelectPopup.classList.add('open');
			document.body.style.overflow = 'hidden';

			// ML 엔진 목록 조회
			this.$axios.post(cctvApi.inqMlEngineInfo).then(res => {
				this.maxConnectionCount = res.headers['max-connection-count'];
				this.mlEngineList = res.data || [];

				// 이미 ML 엔진이 선택되어 있는 경우 팝업 초기 세팅
				if (isAutoScroll && this.detailsItem.mlEngineId) {
					this.selectedMlEngineIdx = this.mlEngineList.findIndex(ml => ml.mlEngineId == this.detailsItem.mlEngineId);

					// 이미 연결된 ML 엔진이 있는 경우에만 자동 클릭 및 스크롤
					this.clickMlEngine(this.selectedMlEngineIdx);
					this.$nextTick(() => {
						const table = this.$refs.mlEngineListTable;
						table.querySelector('.selected').scrollIntoView({
							behavior: 'auto',
							block: 'center',
							inline: 'center',
						});
					});
				}
			});
		},
		// ML 엔진 선택 팝업 닫기
		closeMlEngineSelectPopup() {
			this.$refs.mlEngineSelectPopup.classList.remove('open');
			this.mlEngineList = [];
			this.mlEngineCctvList = [];
			this.selectedMlEngineIdx = -1;
			document.body.style.overflow = 'auto';
		},
		// ML 엔진 선택 팝업에서 ML 엔진 클릭
		clickMlEngine(idx) {
			this.selectedMlEngineIdx = idx;
			const selectedMlEngine = this.mlEngineList[idx];

			// ML 엔진에 연결된 CCTV 목록 조회
			this.$axios.post(cctvApi.inqMlConnectedCctv, { mlEngineId: selectedMlEngine.mlEngineId }).then(res => {
				this.mlEngineCctvList = res.data || [];
			});
		},
		// ML 엔진 선택 팝업에서 ML 엔진 수정 버튼 클릭
		clickEditMlEngineButton(e, mlEngine) {
			e.stopPropagation();
			this.isEditMlEngine = true;
			this.mlDetailsItem = {
				mlEngineId: mlEngine.mlEngineId,
				detectModelDvsnCd: mlEngine.detectModelDvsnCd,
				classifyModelDvsnCd: mlEngine.classifyModelDvsnCd,
			};
			this.$refs.mlEngineEditPopup.classList.add('open');
		},
		// ML 엔진 추가 버튼 클릭
		clickAddMlEngineButton() {
			this.isEditMlEngine = false;
			this.mlDetailsItem = {};
			this.$refs.mlEngineEditPopup.classList.add('open');
		},
		// ML 엔진 삭제 버튼 클릭
		clickDelMlEngineButton() {
			if (confirm('선택한 ML 엔진을 삭제하시겠습니까?')) {
				const selectedMlEngine = this.mlEngineList[this.selectedMlEngineIdx];

				this.$axios.post(cctvApi.delMlEngineInfo, { mlEngineId: selectedMlEngine.mlEngineId }).then(() => {
					this.openSelectMlEngine(true);

					// 방금 삭제한 ML 엔진을 선택하고 저장하진 않은 경우, ML 엔진 값을 비움
					if (selectedMlEngine.mlEngineId === this.detailsItem.mlEngineId) {
						this.detailsItem.mlEngineId = '';
					}
				});
			}
		},
		// ML 엔진 팝업에서 선택 버튼 클릭
		clickSelectMlEngineButton() {
			if (this.mlEngineCctvList.length < this.maxConnectionCount) {
				this.detailsItem.mlEngineId = this.mlEngineList[this.selectedMlEngineIdx].mlEngineId;
				this.closeMlEngineSelectPopup();
			} else {
				alert('선택된 ML 엔진에 더 이상 CCTV를 연결할 수 없습니다.');
			}
		},
		// ML 엔진 수정 팝업 닫기
		closeMlEngineEditPopup() {
			this.$refs.mlEngineEditPopup.classList.remove('open');
			this.mlDetailsItem.detectModelDvsnCd = this.detailsItem.detectModelDvsnCd;
			this.mlDetailsItem.classifyModelDvsnCd = this.detailsItem.classifyModelDvsnCd;
		},
		// ML 엔진 추가 팝업에서 추가 버튼 클릭
		clickSaveMlEngineButton() {
			if (!this.mlDetailsItem.detectModelDvsnCd || !this.mlDetailsItem.classifyModelDvsnCd) {
				return alert('감지 모델 또는 분류 모델을 선택해 주십시오');
			}
			if (confirm('새 ML 엔진 데이터를 추가하시겠습니까?')) {
				this.$axios.post(cctvApi.insMlEngineInfo, this.mlDetailsItem).then(() => {
					this.$refs.mlEngineEditPopup.classList.remove('open');
					this.openSelectMlEngine(false);
					this.$nextTick(() => {
						const table = this.$refs.mlEngineListTable;
						table.querySelector('.tbody .tr:last-child').scrollIntoView();
					});
				});
			}
		},
		// ML 엔진 수정 팝업에서 수정 버튼 클릭
		clickUpdateMlEngineButton() {
			if (!this.mlDetailsItem.detectModelDvsnCd || !this.mlDetailsItem.classifyModelDvsnCd) {
				return alert('감지 모델 또는 분류 모델을 선택해 주십시오');
			}
			if (confirm('ML 엔진 데이터를 수정하시겠습니까?')) {
				this.$axios.post(cctvApi.updMlEngineInfo, this.mlDetailsItem).then(() => {
					this.$refs.mlEngineEditPopup.classList.remove('open');
					this.openSelectMlEngine(false);
				});
			}
		},
	},
};
</script>

<style scoped>
.select-wrapper {
	min-width: 190px !important;
	width: 190px !important;
}
.tooltipText {
	width: 380px;
	background-color: gray;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	top: 40px;
	z-index: 1;
	visibility: hidden;
}

[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
	margin: 0;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}

.panel-body {
	text-align: center;
}

.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.in-panel-body {
	display: inline-block;
	width: 1200px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.itemWrapper > label {
	margin-top: 7px;
}

.itemWrapper.reqInstallDtd .msg:before {
	content: '\ed63';
	font-family: 'icomoon';
	font-size: 16px;
	position: absolute;
	top: 4.3vh;
	left: 0.7vw;
}

.itemWrapper.reqInstallDtd > div > input {
	border: 1px solid #d84315;
}

.required,
.itemWrapper.reqInstallDtd > label {
	color: #d84315;
}
.itemWrapper.reqInstallDtd > div .msg {
	margin-top: 7px;
	color: #f44336;
	display: block;
}
.itemWrapper.reqInstallDtd .msg:after {
	margin-left: 1rem;
	content: '설치일시는 필수 정보입니다.';
}

.ml-engine-field .form-group {
	display: flex;
	margin: 0;
}

.ml-engine-field .form-group .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.ml-engine-field .form-group div.form-control {
	text-align: left;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0;
}

.popup {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}
.popup.open {
	display: flex;
}
.popup > .background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: -1;
}
.popup > .wrapper {
	width: 60vw;
	height: 60vh;
	max-height: 80vh;
	background: white;
	margin: auto;
	z-index: 10;
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.popup .wrapper > div {
	flex: 1;
	display: flex;
	align-items: center;
	padding: 12px;
}
.popup .wrapper > .top {
	height: 50px;
	max-height: 50px;
	font-size: 1.2em;
	font-weight: bold;
	border-bottom: 1px solid #ddd;
}
.popup .wrapper > .top .fa-times {
	font-size: 1.4em;
	margin-left: auto;
	margin-right: 10px;
	cursor: pointer;
}
.ml-engine-select-popup .wrapper > .middle {
	border-bottom: 1px solid #ddd;
	height: 80vh;
	max-height: 80vh;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.ml-engine-select-popup .wrapper > .middle > div {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.ml-engine-select-popup .wrapper > .middle > div > label {
	font-size: 1.2em;
}
.ml-engine-select-popup .wrapper > .middle > div > .table {
	position: relative;
	display: flex;
	flex-direction: column;
	border: 1px solid #ddd;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow: auto;
	font-size: 1.1em;
	padding-top: 34px;
}
.ml-engine-select-popup .wrapper > .middle > .cctv-list .table {
	border-left: 0;
}
.ml-engine-select-popup .wrapper > .middle > div > .table > div {
	width: 100%;
}
.ml-engine-select-popup .wrapper > .middle > div > .table .tr {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #ddd;
}
.ml-engine-select-popup .wrapper > .middle > div > .table .tr > span {
	flex: 1 1 33.33%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding: 6px 10px;
}
.ml-engine-select-popup .wrapper > .middle > div > .table .thead {
	position: absolute;
	top: 0;
	left: 0;
	font-weight: bold;
	background: rgb(66, 66, 67);
	color: white;
	border-bottom: 1px solid #ddd;
}
.ml-engine-select-popup .wrapper > .middle > div > .table .thead .tr {
	border-bottom: 0;
}
.ml-engine-select-popup .wrapper > .middle > div > .table .tr span {
	text-align: center;
}
.ml-engine-select-popup .wrapper > .middle > div > .table .tbody {
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	padding-bottom: 68px;
}
.ml-engine-select-popup .wrapper > .middle > div > .table .tbody:empty:after {
	content: '조회된 결과가 없습니다';
	color: gray;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .thead .tr span:nth-child(1),
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .thead .tr span:nth-child(2),
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .thead .tr span:nth-child(5) {
	flex: 1 1 20%;
}
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .thead .tr span:last-child {
	flex: 1 1 10%;
}

.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr {
	cursor: pointer;
}
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr:hover {
	background: #eee;
}
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr.selected {
	background: rgba(68, 206, 248, 0.4);
}

.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr span:nth-child(1),
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr span:nth-child(2),
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr span:nth-child(5) {
	flex: 1 1 20%;
}
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr span:last-child {
	flex: 1 1 10%;
}
.ml-engine-select-popup .wrapper > .middle > .ml-engine-list > .table .tbody .tr span:last-child button {
	width: 20px;
	height: 20px;
	font-size: 0.6em;
	padding: 4px;
}

.ml-engine-select-popup .wrapper > .middle > .cctv-list > .table .tbody .tr {
	cursor: default;
}

.ml-engine-select-popup .wrapper > .bottom {
	height: 60px;
	max-height: 60px;
}

.ml-engine-select-popup .wrapper > .bottom button {
	margin-right: 12px;
}

.ml-engine-select-popup .wrapper > .bottom button:last-child {
	margin-left: auto;
	margin-right: 0;
}

.popup.ml-engine-edit-popup > .wrapper {
	width: 300px;
	height: 300px;
}
.popup.ml-engine-edit-popup > .wrapper .middle {
	flex-direction: column;
	align-items: flex-start;
}
.popup.ml-engine-edit-popup > .wrapper .middle label:last-of-type {
	margin-top: 16px;
}
.popup.ml-engine-edit-popup > .wrapper .bottom > button {
	margin-left: auto;
}
</style>
